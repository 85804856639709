






















































import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "@/plugins/firebase";

@Component({
    name: "Login"
})
export default class Login extends Vue {
    @Prop(Boolean)
    public readonly immediate!: boolean;

    private created() {
        if(this.immediate)
            this.dialog = true;
    }

    public dialog = false;

    public valid = true;

    public resetSuccess = false;

    public resetError = false;

    public icons = {
        mdiEye,
        mdiEyeOff
    }

    public message = "";

    private loading = false;

    public data = {
        email: "",
        password: ""
    }

    public rules = {
        required: (v: string) => !!v || "Required"
    }

    public show = false;

    public async login() {
        if(!this.valid) return;
        try {
            this.loading = true;
            const user = await signInWithEmailAndPassword(auth, this.data.email, this.data.password);
            this.close();

            if(!user.user)
                throw new Error("User is not defined");

            this.$router.push({ name: "Course" });
        }
        catch(error) {
            this.message = (error as Error).message;
        }
        finally {
            this.loading = false;
        }
    }

    public resetting = false;

    public async resetPassword() {
        if(this.resetting) return;

        if(!this.data.email) {
            this.resetError = true;
            return;
        }

        try {
            this.resetting = true;
            await sendPasswordResetEmail(auth, this.data.email);
            this.resetting = false;
            this.resetSuccess = true;
        }
        catch(error) {
            this.message = (error as Error).message;
        }
    }

    public open() {
        this.dialog = true;
    }

    public close() {
        this.message = "";
        (this.$refs["form"] as any).reset();
        this.show = false;
        this.dialog = false;

        this.$emit("close");
    }
}
