import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const matches = window.matchMedia("(prefers-color-scheme: dark)");

let isDark = matches.matches;

if(matches.addEventListener)
    matches.addEventListener("change", m => {
        isDark = m.matches;
    });

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg"
    },
    theme: {
        dark: isDark
    }
});
