import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: {
            layout: "FullscreenLayout"
        }
    },
    {
        path: "/login",
        redirect: {
            name: "Home",
            query: {
                login: "true"
            }
        }
    },
    {
        path: "/register",
        redirect: {
            name: "Home",
            query: {
                register: "true"
            }
        }
    },
    {
        path: "/course",
        component: () => import("@/views/Course.vue"),
        children: [
            {
                path: "",
                name: "Course",
                component: () => import("@/components/WDay.vue")
            },
            {
                path: "dashboard",
                name: "Dashboard",
                component: () => import("@/views/DayDashboard.vue")
            }
        ]
    },
    {
        path: "/directions/:city",
        name: "Directions",
        component: () => import("@/views/Directions.vue")
    }
];

if (process.env.NODE_ENV === "development") {
    routes.push(
        {
            path: "/issues",
            name: "Issues",
            component: () => import("@/views/Issues.vue")
        }
    );
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
