































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { setDoc, doc } from "firebase/firestore";
import { db, auth, functions } from "@/plugins/firebase";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

const textConfirmation = httpsCallable(functions, "textConfirmation");

@Component({
    name: "Register"
})
export default class Register extends Vue {
    @Prop(Boolean)
    public readonly immediate!: boolean;

    private created() {
        if(this.immediate)
            this.dialog = true;
    }

    public dialog = false;

    public icons = {
        mdiEye,
        mdiEyeOff
    }

    public message = "";

    private loading = false;

    private valid = false;

    public data = {
        first: "",
        last: "",
        email: "",
        phone: {
            number: "",
            valid: false
        },
        password: "",
        confirm: ""
    }

    public rules = {
        required: (value: string) => !!value || "Required",
        password: (value: string) => value.length > 5 || "Password must be at least 6 characters",
        confirm: (value: string) => (!!value && value) === this.data.password || "Passwords do not match",
        email: (value: string) => {
            const pattern = /^([ ])*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))([ ])*$/;
            return pattern.test(value) || "Invalid e-mail";
        },
        number: (value: string) => (/^([ ])*\(\d{3}\) \d{3}-\d{4}([ ])*$/).test(value) || "Invalid phone number"
    }

    public show = false;

    public async register() {
        const valid: boolean = (this.$refs["form"] as any).validate() && this.data.phone.valid;

        if(!valid)
            return;

        try {
            this.loading = true;
            const { user } = await createUserWithEmailAndPassword(auth, this.data.email.trim(), this.data.password);
            if(user) {
                const promises: Promise<any>[] = [];

                promises.push(updateProfile(user, {
                    displayName: `${this.data.first.trim()} ${this.data.last.trim()}`
                }));
                promises.push(setDoc(doc(db, "users", user.uid), {
                    phone: this.data.phone.number.trim()
                }, { merge: true }));
                promises.push(sendEmailVerification(user));

                await Promise.all(promises);
                textConfirmation();
            }
            else {
                console.error("User is null");
                throw new Error("User is null");
            }
            this.close();
        }
        catch(error) {
            this.message = (error as Error).message;
        }
        finally {
            this.loading = false;
        }
    }

    public open() {
        this.dialog = true;
    }

    public close() {
        this.message = "";
        (this.$refs["form"] as any).reset();
        this.show = false;
        this.dialog = false;

        this.$emit("close");
    }

    public onPhoneInput(formatted: string, { number, valid }: { number: { e164: string }; valid: boolean }) {
        this.data.phone.number = number.e164;
        this.data.phone.valid = valid;
    }
}
