























import { Vue, Component } from "vue-property-decorator";
import FooterComponent from "@/components/Footer.vue";
import { logout } from "@/plugins/firebase";

@Component({
    name: "MainLayout",
    components: {
        FooterComponent
    }
})
export default class MainLayout extends Vue {
    async logout() {
        await logout();
        this.$router.push({ name: "Home" });
    }
}
