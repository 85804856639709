

















import { Vue, Component } from "vue-property-decorator";
// import { messaging } from "@/plugins/firebase";

@Component({
    name: "App"
})
export default class App extends Vue {
    created() {
        // messaging?.getToken({ vapidKey: "BGtlxQCUMJCUiRNu4lbufz1mHF4lcvZS6FqGsvdSl5p7XRd_NFG7VPjffVAxADD-AXhPoTJjuPo3Kxc95I4uC5E" }).then(t => {
        //     Vue.prototype.$firebase.token = t;
        //     if(this.$firebase.auth.currentUser?.uid)
        //         this.$firebase.db.collection("users").doc(this.$firebase.auth.currentUser?.uid).update({ devices: firebase.firestore.FieldValue.arrayUnion(this.$firebase.token) });
        //     console.log(t);
        // })
        // .catch(err => {
        //     console.error(err);
        // });
    }

    get layout() {
        return this.$route.meta?.layout || "MainLayout";
    }
}
