import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/plugins/layout";
import vuetify from "./plugins/vuetify";
import installFirebase from "@/plugins/firebase";
import "@/assets/animations/animations.css";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
    vuetify
});

class Startup {
    private app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    });

    public async configure() {
        await installFirebase({ app: this.app, store, router });
    }

    public start() {
        this.app.$mount("#app");
    }
}

async function main() {
    const startup = new Startup();

    startup.configure();
    startup.start();
}

main();