import Vue from "vue";
import type { PluginParameters } from "./plugin";
import { onAuthStateChanged, getAuth, Auth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { isSupported, getMessaging, Messaging } from "firebase/messaging";
import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDKhKknIKheAA0KQjDpfsjPEWxlLW_mXUY",
    authDomain: "wmammogram.firebaseapp.com",
    projectId: "wmammogram",
    storageBucket: "wmammogram.appspot.com",
    messagingSenderId: "140268805274",
    appId: "1:140268805274:web:0676ad806593cdc74ad323",
    measurementId: "G-9FK858K3HS"
};

export const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
export let messaging: null | Messaging = null;
export const analytics = getAnalytics(app);
export const auth = getAuth();
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LfJdUsbAAAAAJSqvv7JVQ9fZcO90C8Fjk5lY6mM"),
    isTokenAutoRefreshEnabled: true
});
export const functions = getFunctions(app);

isSupported().then(() => messaging = getMessaging(app));

enableIndexedDbPersistence(db)
    .catch((err) => {
        if (err.code == "failed-precondition") {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
        }
        else if (err.code == "unimplemented") {
            // The current browser does not support all of the
            // features required to enable persistence
        }
    });

Vue.prototype.$firebase = {
    auth,
    messaging,
    analytics,
    token: null
};

declare module "vue/types/vue" {
    interface Vue {
        $firebase: {
            auth: Auth;
            messaging?: Messaging;
            analytics: Analytics;
            token: string | null;
        };
    }
}

export default async function({ store }: PluginParameters) {
    return new Promise<void>((resolve) => {
        onAuthStateChanged(auth, async user => {
            // Signed in
            if(user) {
                store.commit("setUser", user);
                store.dispatch("subRibbons");
            }
            // signed out
            else {
                store.commit("setUser", null);
                store.dispatch("unsubRibbons");
            }
            store.commit("setGlobalLoading", false);
            resolve();
        });
    });
}

export async function logout() {
    // if(this.$firebase.token)
    //     await this.$firebase.db
    //         .collection("users")
    //         .doc(this.$store.state.user.uid)
    //         .set({
    //             devices: firebase.firestore.FieldValue.arrayRemove(this.$firebase.token)
    //         }, { merge: true });
    await auth.signOut();
}