import Vue from "vue";
import Vuex from "vuex";
import type { User } from "firebase/auth";
import { collection, onSnapshot, query, where, Unsubscribe } from "firebase/firestore";
import { db } from "@/plugins/firebase";

Vue.use(Vuex);

export class RootStore {
    user: User | null = null;
    ribbons: number | null = null;
    unsubRibbons: Unsubscribe | null = null;
    loading = true;
}

export default new Vuex.Store<RootStore>({
    state: new RootStore(),
    modules: {

    },
    mutations: {
        setUser(state, user: User) {
            state.user = user;
        },
        setUnsub(state, unsub: Unsubscribe) {
            state.unsubRibbons = unsub;
        },
        setRibbons(state, ribbons: number) {
            state.ribbons = ribbons;
        },
        setGlobalLoading(state, loading: boolean) {
            state.loading = loading;
        },
    },
    actions: {
        async subRibbons({ commit, state }) {
            if (!state.user) return;
            const q = query(collection(db, "users", state.user.uid, "answers"), where("correct", "==", true));
            const unsub = onSnapshot(q, doc => {
                commit("setRibbons", doc.size);
            });
            commit("setUnsub", unsub);
        },
        async unsubRibbons({ commit, state }) {
            if (state.unsubRibbons)
                state.unsubRibbons();
            commit("setUnsub", null);
        }
    },
    getters: {

    }
});
