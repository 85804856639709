




































import { Vue, Component, Watch } from "vue-property-decorator";
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import type { Route } from "vue-router";
import FooterComponent from "@/components/Footer.vue";
import { logout } from "@/plugins/firebase";

@Component({
    name: "FullscreenLayout",
    components: {
        Login,
        Register,
        FooterComponent
    }
})
export default class FullscreenLayout extends Vue {
    private offset = 0;

    onScroll(e: Event) {
        this.offset = (e.target as Document).documentElement.scrollTop;
    }

    scrollTop() {
        this.$vuetify.goTo(0);
    }

    get transparent() {
        return this.offset === 0;
    }

    get isDark() {
        return this.transparent || this.$vuetify.theme.dark;
    }

    get login() {
        return !!this.$route.query.login;
    }

    get register() {
        return !!this.$route.query.register;
    }

    @Watch("$route")
    onRouteChange(route: Route) {
        if(route.query?.login) {
            (this.$refs["register"] as Register).close();
            (this.$refs["login"] as Login).open();
        }
        else if(route.query?.register) {
            (this.$refs["login"] as Login).close();
            (this.$refs["register"] as Register).open();
        }
    }

    private async logout() {
        await logout();
    }

    private onClose() {
        if(this.$route.query.login || this.$route.query.register)
            this.$router.replace({ query: {}});
    }
}
